@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  @apply bg-th-bkg-1;
  overflow-x: hidden;
  width: 100%;
  font-size: 13.7142857px;
}

html[data-theme='Stargazer'],
[data-theme='Stargazer'] body {
  background-image: radial-gradient(
    187.34% 187.31% at 55.97% -84.49%,
    #4e52b8 0%,
    #000805 56.68%
  );
}

.bg-gradient {
  background: url('/images/Theme.png');
  background-position: top;
  background-size: cover;
}

html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

ul {
  list-style: none;
}

button,
input,
select {
  margin: 0;
}

html {
  box-sizing: border-box;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}

/* Ensure images and videos are responsive */
img,
video {
  height: auto;
  max-width: 100%;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

/* Theme */
:root {
  overscroll-behavior: none;
}
:root {
  --active: theme('colors.patek.brand.primary');
  --active-icon: theme('colors.patek.brand.icon');
  --down: theme('colors.patek.error.primary');
  --down-dark: theme('colors.patek.error.secondary');
  --up: theme('colors.patek.success.primary');
  --up-dark: theme('colors.patek.success.secondary');
  --bkg-1: theme('colors.patek.background.surface-primary');
  --bkg-2: theme('colors.patek.background.surface-secondary');
  --bkg-3: theme('colors.patek.background.surface-highlight');
  --bkg-4: theme('colors.patek.background.surface-grey');
  --bkg-5: theme('colors.patek.background.surface-modal');
  --bkg-6: theme('colors.patek.background.bkg-6');
  --fgd-1: theme('colors.patek.greyscale.text-primary');
  --fgd-2: theme('colors.patek.greyscale.text-secondary');
  --fgd-3: theme('colors.patek.greyscale.grey-secondary');
  --fgd-4: theme('colors.patek.greyscale.grey-darker');
  --fgd-5: theme('colors.patek.greyscale.grey-territory');
  --button: theme('colors.patek.button.DEFAULT');
  --button-hover: theme('colors.patek.button.hover');
  --input-bkg: theme('colors.patek.background.surface-secondary');
  --input-border: theme('colors.patek.border.primary');
  --input-border-secondary: theme('colors.patek.border.secondary');
  --input-border-hover: theme('colors.patek.greyscale.text-primary');
  --error: theme('colors.patek.error.primary');
  --success: theme('colors.patek.success.primary');
  --warning: theme('colors.patek.warning.primary');
  --table-hover: theme('colors.patek.background.surface-secondary');
}

[data-theme='Patek'] {
  --active: theme('colors.patek.brand.primary');
  --active-icon: theme('colors.patek.brand.icon');
  --down: theme('colors.patek.error.primary');
  --down-dark: theme('colors.patek.error.secondary');
  --up: theme('colors.patek.success.primary');
  --up-dark: theme('colors.patek.success.secondary');
  --bkg-1: theme('colors.patek.background.surface-primary');
  --bkg-2: theme('colors.patek.background.surface-secondary');
  --bkg-3: theme('colors.patek.background.surface-highlight');
  --bkg-4: theme('colors.patek.background.surface-grey');
  --bkg-5: theme('colors.patek.background.surface-modal');
  --fgd-1: theme('colors.patek.greyscale.text-primary');
  --fgd-2: theme('colors.patek.greyscale.text-secondary');
  --fgd-3: theme('colors.patek.greyscale.grey-secondary');
  --fgd-4: theme('colors.patek.greyscale.grey-darker');
  --fgd-5: theme('colors.patek.greyscale.grey-territory');
  --button: theme('colors.patek.button.DEFAULT');
  --button-hover: theme('colors.patek.button.hover');
  --input-bkg: theme('colors.patek.background.surface-secondary');
  --input-border: theme('colors.patek.border.primary');
  --input-border-secondary: theme('colors.patek.border.secondary');
  --input-border-hover: theme('colors.patek.greyscale.text-primary');
  --error: theme('colors.patek.error.primary');
  --error-secondary: theme('colors.patek.error.secondary');
  --success: theme('colors.patek.success.primary');
  --success-secondary: theme('colors.patek.success.secondary');
  --warning: theme('colors.patek.warning.primary');
  --table-hover: theme('colors.patek.background.surface-secondary');
}

[data-theme='Stargazer'] {
  --active: theme('colors.stargazer.brand.primary');
  --active-icon: theme('colors.stargazer.brand.icon');
  --down: theme('colors.stargazer.error.primary');
  --down-dark: theme('colors.stargazer.error.secondary');
  --up: theme('colors.stargazer.success.primary');
  --up-dark: theme('colors.stargazer.success.secondary');
  --bkg-1: theme('colors.stargazer.background.surface-primary');
  --bkg-2: theme('colors.stargazer.background.surface-secondary');
  --bkg-3: theme('colors.stargazer.background.surface-highlight');
  --bkg-4: theme('colors.stargazer.background.surface-grey');
  --bkg-5: theme('colors.stargazer.background.surface-modal');
  --fgd-1: theme('colors.stargazer.greyscale.text-primary');
  --fgd-2: theme('colors.stargazer.greyscale.text-secondary');
  --fgd-3: theme('colors.stargazer.greyscale.grey-secondary');
  --button: theme('colors.stargazer.button.DEFAULT');
  --button-hover: theme('colors.stargazer.button.hover');
  --input-bkg: theme('colors.stargazer.background.surface-secondary');
  --input-border: theme('colors.stargazer.border.primary');
  --input-border-secondary: theme('colors.stargazer.border.secondary');
  --input-border-hover: theme('colors.stargazer.greyscale.text-primary');
  --error: theme('colors.stargazer.error.primary');
  --error-secondary: theme('colors.stargazer.error.secondary');
  --success: theme('colors.stargazer.success.primary');
  --success-secondary: theme('colors.stargazer.success.secondary');
  --warning: theme('colors.stargazer.warning.primary');
  --table-hover: theme('colors.stargazer.background.surface-highlight');
}

/* Base */

body {
  @apply font-body text-sm font-medium tracking-wider;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.noise::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
  background: url('https://d6ywjvkyvtau.cloudfront.net/public/images/bg-noise.png') !important;
  opacity: 0.8;
  z-index: 50;
}

main {
  @apply font-flexo;
}

button {
  @apply default-transition tracking-wider focus:outline-none;
  -webkit-font-feature-settings: 'zero' 1;
  font-feature-settings: 'zero' 1;
}

svg {
  @apply transition duration-500 ease-out;
}

.default-transition {
  @apply transition duration-300 ease-out;
}

h1,
h2,
h3,
h4 {
  @apply font-bold text-th-fgd-1;
}

h1 {
  @apply text-3xl;
}

h2 {
  @apply text-2xl;
}

h3 {
  @apply text-lg;
}

p {
  @apply text-th-fgd-3;
}

li {
  @apply text-sm text-th-fgd-3;
}

a {
  @apply default-transition text-th-fgd-1 md:hover:text-th-fgd-3;
}

input {
  @apply default-transition;
}

input::placeholder {
  @apply text-th-fgd-3;
}

input::selection,
textarea::selection {
  background-color: #1262e2 !important;
}

/* Remove inner shadow from inputs on mobile iOS */
textarea,
input[type='text'] {
  -webkit-appearance: none;
}

input[type='text'] {
  min-height: 32px;
}

/* Scrollbars */

.hide-scroll::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  -webkit-appearance: none;
}

.hide-scroll::-webkit-scrollbar-thumb {
  border: 0px solid transparent;
  background-clip: padding-box;
}

.hide-scroll::-webkit-scrollbar-thumb:hover {
  border: 0;
}

.hide-scroll::-webkit-scrollbar-track {
  background: transparent;
}

.thin-scroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.thin-scroll::-webkit-scrollbar-thumb {
  @apply rounded bg-th-bkg-4;
  border: 2px solid transparent;
  background-clip: padding-box;
}

.thin-scroll::-webkit-scrollbar-thumb:hover {
  border: 0;
}

.thin-scroll::-webkit-scrollbar-track {
  background: transparent;
}

.thin-scroll::-webkit-scrollbar-thumb:window-inactive {
  @apply bg-th-bkg-4;
}

/* Chart */

.TVChartContainer {
  height: 100%;
  width: 100%;
}

.tradingview-chart {
  display: contents;
}

/* slider */

input[type='range'] {
  @apply default-transition h-1.5 appearance-none rounded border border-th-input-border bg-th-bkg-1 bg-gradient-to-r from-th-active to-th-active bg-no-repeat focus-visible:bg-th-bkg-4;
}

input[type='range']::-webkit-slider-thumb {
  @apply h-5 w-5 cursor-ew-resize appearance-none rounded-full bg-th-fgd-1 shadow;
  border: 0.5px solid var(--active);
  box-shadow: 0px 0px 5px 0px var(--active-icon);
}

input[type='range']::-webkit-slider-runnable-track {
  @apply appearance-none border-0 bg-transparent shadow-none;
}

.tooltip-underline {
  @apply default-transition w-max border-b border-dashed border-current hover:cursor-help hover:border-transparent;
}

#tsparticles {
  @apply absolute h-screen w-full;
}

/* raised button */

.raised-button {
  @apply relative z-10 transition-none;
}

.raised-button:after {
  @apply absolute left-0 top-0 h-full w-full bg-th-button;
  content: '';
  box-shadow: 0 4px var(--button-hover);
  z-index: -1;
}

.raised-button:hover:after {
  background-color: var(--button);
  box-shadow: 0 2px var(--button-hover);
  top: 2px;
}

.raised-button:active:after {
  box-shadow: 0 0 var(--button-hover);
  top: 4px;
}

/* removes scrollbar from everywhere */
*::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

* {
  font-family: var(--font-body);
}

* {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.Position-list-order:hover {
  background: var(--input-border-secondary);
}

.Position-list-order {
  cursor: pointer;
}

.Position-list-order:not(:first-child) {
  margin-top: 8px;
}

.Position-list-order .Position-list-order-label {
  display: flex;
  justify-content: space-between;
  align-self: center;
}

.Confirmation-box-info,
.Confirmation-box-info a,
.Confirmation-box-warning,
.Confirmation-box-warning a {
  font-size: 14px;
  margin-top: 12px;
}

.Confirmation-box-info,
.Confirmation-box-info a {
  color: var(--warning);
}

.Confirmation-box-warning,
.Confirmation-box-warning a {
  color: var(--down);
}

.Exchange-swap-button-container {
  padding-top: 0.31rem;
}

.Exchange-swap-button-container .long,
.Exchange-swap-button-container .long:hover {
  background-color: var(--up);
}

.Exchange-swap-button-container .short,
.Exchange-swap-button-container .short:hover {
  background-color: var(--down);
}

.Exchange-swap-button-container .swap,
.Exchange-swap-button-container .swap:hover {
  background-color: var(--active);
  color: var(--fgd-1);
}

.Exchange-swap-button {
  display: block;
  width: 100%;
}

.Exchange-swap-ball-container {
  position: relative;
  z-index: 1;
  margin-top: 2.5rem;
  margin-bottom: 4rem;
}

.Exchange-swap-ball-icon {
  text-align: center;
  display: block;
  font-size: 2rem;
  opacity: 0.8;
}

.Exchange-swap-ball {
  position: absolute;
  width: 3.5rem;
  height: 3.5rem;
  left: 50%;
  margin-left: -1.7825rem;
  top: -2rem;
  border-radius: 3.1rem;
  cursor: pointer;
  user-select: none;
  background: var(--primary-btn-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: var(--fgd-1);
}

.Exchange-swap-ball:hover {
  background: var(--primary-btn-hover);
}

.Exchange-swap-ball:active {
  background: var(--primary-btn-active);
}

.Exchange-swap-ball:hover .Exchange-swap-ball-icon {
  opacity: 1;
}

.Confirmation-box {
  font-size: 2rem;
}

.Confirmation-box-main {
  text-align: center;
  display: flex;
  flex-direction: column;
  color: var(--fgd-1);
  margin-top: 12px;
}

.position-list-collateral {
  display: flex;
  align-items: flex-end;
}

.position-list-collateral .edit-icon {
  cursor: pointer;
  margin-left: 0.5rem;
  display: inline-flex;
  align-items: center;
  opacity: 0.7;
}
.position-list-collateral .edit-icon:hover {
  opacity: 1;
}

@media (max-width: 800px) {
  .Exchange {
    padding-top: 1.5rem !important;
  }
}

.chart-options-button-width {
  width: 100%;
}

@media (min-width: 640px) {
  .chart-options-button-width {
    width: 240px;
  }
}

.spin {
  animation: spin-animation infinite 1.2s linear;
}

@keyframes spin-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.glass-bg {
  background: var(
    --Dark-Background-Secondary,
    linear-gradient(
      180deg,
      rgba(98, 106, 136, 0.1) 0%,
      rgba(46, 49, 73, 0.1) 100%
    )
  );
  backdrop-filter: blur(12px);
}

.glass-bg-light {
  background: var(
    --Dark-Background-Secondary,
    linear-gradient(
      180deg,
      rgba(98, 106, 136, 0.1) 0%,
      rgba(46, 49, 73, 0.1) 100%
    )
  );
  backdrop-filter: blur(6px);
}

.market-token-selector-glass-bg {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border-bottom: 1px solid var(--input-border);
  background: linear-gradient(
    180deg,
    rgba(98, 106, 136, 0.1) 0%,
    rgba(46, 49, 73, 0.1) 100%
  );
  backdrop-filter: blur(12px);
}

@media (min-width: 640px) {
  .market-token-selector-glass-bg {
    border-right: 1px solid var(--input-border);
  }
}

.connect-wallet {
  @apply !bg-th-active !text-th-fgd-1 hover:!bg-th-fgd-1 hover:!text-th-bkg-1;
}

.glassmorphism-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background: var(--bkg-5);
}

.glassmorphism {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(98, 106, 136, 0.1) 0%,
    rgba(46, 49, 73, 0.1) 100%
  );
  backdrop-filter: blur(12px);
}

.glass-background {
  background: linear-gradient(
    180deg,
    rgba(98, 106, 136, 0.1) 0%,
    rgba(46, 49, 73, 0.1) 100%
  );
}

.react-datepicker-popper {
  z-index: 60;
  width: fit-content !important;
  left: calc(-10% - 16px) !important;
  right: 0% !important;
}

.negative {
  color: var(--error);
}

.positive {
  color: var(--success);
}

.warning {
  color: var(--warning);
}

/* toast */

.Toastify__toast {
  background: linear-gradient(
    180deg,
    rgba(98, 106, 136, 0.1) 0%,
    rgba(46, 49, 73, 0.1) 100%
  ) !important;
  backdrop-filter: blur(12px);
  color: var(--fgd-1) !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  border: 0.5px solid var(--input-border);
  position: relative;
}

.Toastify__toast-container.Toastify__toast-container--bottom-right {
  width: 396px;
  right: 0px;
}

button.Toastify__close-button.Toastify__close-button--light {
  @apply !mt-[4px];
}

.Toastify__toast-body {
  position: relative;
  z-index: 2;
  @apply flex items-start;
}

.Toastify__toast::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background: var(--bkg-5);
  z-index: -1;
}

.Toastify__toast::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('https://d6ywjvkyvtau.cloudfront.net/public/images/bg-noise.png') !important;
  z-index: 1;
  pointer-events: none;
}

.Toastify__close-button svg {
  fill: var(--button) !important;
}

.Toastify__progress-bar {
  background: var(--active) !important;
  box-shadow: 0px 0px 5px 0px var(--active-icon) !important;
}

/* ticker */

.animation-infinite-ticker {
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: animate-infinite-ticker;
  animation-duration: 150s;
  cursor: default;
}
.animation-infinite-ticker:hover {
  animation-play-state: paused;
}

@keyframes animate-infinite-ticker {
  0% {
    transform: translateX(5%);
  }
  100% {
    transform: translateX(-95%);
  }
}

/* end ticker */
.show-recent-order .toolbar-qqNP9X6e {
  width: 254px !important;
}

.progress-bar-outer-container {
  width: 28px;
  height: 8px;
  -webkit-mask: linear-gradient(90deg, #000 70%, #0000 0) 0/20%;
  background: linear-gradient(#000 0 0) 0/0% no-repeat #202020;
}

.progress-bar-inner-container {
  @apply bg-th-warning;
  height: 100%;
}

.gmlist-row:hover {
  background-color: var(--table-hover);
  z-index: 20;
}

.glass-modal {
  background: linear-gradient(
    180deg,
    rgba(98, 106, 136, 0.1) 0%,
    rgba(46, 49, 73, 0.1) 100%
  );
}

.level-progress-bar {
  background: linear-gradient(88deg, #a4a7ff 1.99%, #696eff 101.32%);
  box-shadow: 0px 0px 5px 0px #a5a8ff;
}

.success-icon {
  fill: var(--up);
}

/* rainbow-kit-font-size-ovwewrite */
#rk_connect_title {
  font-size: 16px;
}

div.iekbcc0.ju367v5p {
  font-size: 14px;
}

div.iekbcc0.ju367v2v {
  font-size: 12px;
}
div.iekbcc0.ju367v34 div.ju367vgu {
  font-size: 16px;
}
