.LeverageSlider {
  .rc-slider-tooltip {
    z-index: 5;
  }

  .rc-slider-rail {
    @apply h-2 border border-th-input-border bg-th-bkg-1;
  }

  .rc-slider-track {
    @apply h-2 border border-th-input-border bg-th-active;
  }
  .rc-slider-dot {
    @apply h-1 w-1 rounded-full border-none bg-th-fgd-3;
  }
  .rc-slider-dot:last-of-type {
    margin-left: -5px;
  }
  .rc-slider-dot-active {
    @apply bg-th-button;
  }
  .rc-slider-handle {
    background: var(--button);
    opacity: 1;
    height: 20px;
    width: 20px;
    border: solid 1px var(--active);
    box-shadow: 0px 0px 5px 0px var(--active-icon);
  }

  .rc-slider-handle:active {
    border: solid 1px var(--active);
    box-shadow: 0px 0px 5px 0px var(--active-icon);
  }

  .rc-slider-handle:hover {
    border: solid 1px var(--active) !important;
    box-shadow: 0px 0px 5px 0px var(--active-icon) !important;
  }

  .rc-slider-handle-click-focused:focus {
    border: solid 1px var(--active) !important;
    box-shadow: 0px 0px 5px 0px var(--active-icon) !important;
  }

  .rc-slider-mark {
    @apply mt-1 flex justify-between;
  }

  .rc-slider-mark-text {
    color: var(--fgd-3);
    font-size: 12px;
  }

  .rc-slider-mark-text-active {
    color: var(--button);
  }

  .rc-slider-mark-text:hover,
  .rc-slider-mark-text-active:hover {
    opacity: 1;
  }
}
